<template>
  <div>
    <h1>---: not found this page :---</h1>
  </div>
</template>

<script>
export default {
  name: "NotFoundError404Page",
  created() {},
  data() {
    return {};
  },
  props: {},
  methods: {},
};
</script>

<style lang="scss" scoped></style>
